import cn from "classnames";
import React from "react";
import Spinner from "../Spinner";
import styles from "./OutlineButton.module.css";

type Props = {
    children?: React.ReactNode;
    className?: string;
    disabled?: boolean;
    label?: string;
    labelClassName?: string;
    label2?: string;
    mode?: "blue" | "solid_blue" | "white" | "red";
    onClick?: () => void;
    type?: "button" | "submit" | "reset";
    loading?: boolean;
};

const modeToClassName = {
    blue: styles.blue,
    solid_blue: styles.solidBlue,
    white: styles.white,
    red: styles.red,
};

function OutlineButton(
    {
        children,
        className,
        disabled = false,
        label,
        labelClassName,
        label2,
        mode = "white",
        onClick,
        type = "button",
        loading = false,
    }: Props,
    ref: React.ForwardedRef<HTMLButtonElement>,
) {
    function renderContent() {
        if (children) {
            return (
                <>
                    {children}
                    {loading ? (
                        <div className={styles.spinner}>
                            <Spinner isSmall={true} />
                        </div>
                    ) : null}
                </>
            );
        }
        return (
            <>
                <div className={cn(styles.label, labelClassName)}>{label}</div>
                {label2 ? <div className={styles.label}>{label2}</div> : null}
                {loading ? (
                    <div className={styles.spinner}>
                        <Spinner isSmall={true} />
                    </div>
                ) : null}
            </>
        );
    }

    return (
        <button
            ref={ref}
            className={cn(
                styles.button,
                modeToClassName[mode],
                className,
                label && label2 ? styles.buttonTwoLabels : null,
                disabled || loading ? styles.disabled : null,
                loading ? styles.loading : null,
            )}
            disabled={disabled || loading}
            onClick={disabled || loading ? undefined : onClick}
            type={type}>
            {renderContent()}
        </button>
    );
}

export default React.forwardRef<HTMLButtonElement, Props>(OutlineButton);
