import * as posModels from "./posModels";
import instance from "./instance";
import {API} from "habit-core";
import {creditCardScanner as urls} from "./urls";

export type DeviceResponse = {
    isSuccess: boolean;
    result: string;
    resultCode: string;
    responseText: string;
    counter: string;
};

export function registerStationCardReader(
    stationMode: posModels.StationMode,
    deviceCode: string,
): Promise<boolean> {
    return registerDevice(stationMode, deviceCode);
}

export function registerKioskCardReader(
    kioskName: posModels.KioskDeviceName,
    deviceCode: string,
): Promise<boolean> {
    return registerDevice(kioskName, deviceCode);
}

function registerDevice(
    deviceName: string,
    deviceCode: string,
): Promise<boolean> {
    return instance
        .request({
            url: urls.register,
            method: "GET",
            params: {
                deviceName,
                deviceCode,
            },
        })
        .then((response) => response.data)
        .then((data: DeviceResponse) => {
            return data.isSuccess;
        });
}

export function testStationCardReaderConnection(
    stationMode: posModels.StationMode,
): Promise<boolean> {
    return testConnection(stationMode);
}

export function testKioskDeviceConnection(
    kioskDevice: posModels.KioskDeviceName,
): Promise<boolean> {
    return testConnection(kioskDevice);
}

function testConnection(deviceName: string) {
    return instance
        .request({
            url: urls.testConnection,
            method: "GET",
            params: {
                deviceName: deviceName,
            },
        })
        .then((response) => response.data)
        .then((data: DeviceResponse) => {
            return data.isSuccess;
        });
}

export function processPayment(
    stationMode: posModels.StationMode,
    refNum: number,
    orderNum: number,
    checkId: string,
    txnAmtCents: API.models.USDCents,
    taxAmtCents: API.models.USDCents,
    hideTipsPrompt: boolean,
): Promise<posModels.ProcessPaymentResponse> {
    return instance
        .request({
            url: urls.processPayment,
            method: "POST",
            data: {
                MachineName: stationMode, // card readers will be configured to correspond to station modes
                RefNum: refNum,
                OrderNum: orderNum,
                CheckId: checkId,
                TxnAmt: txnAmtCents,
                TaxAmt: taxAmtCents,
                Source: "pos",
                HideTipsPrompt: hideTipsPrompt ? 1 : 0,
            },
        })
        .then((response) => response.data);
}
