import * as posModels from "../../lib/api/posModels";
import * as routeHelpers from "../../lib/routeHelpers";
import OutlineButton from "../OutlineButton";
import React from "react";
import styles from "./OrdersCurrentOrder.module.css";
import TimeSince from "../TimeSince";
import {API, Lib} from "habit-core";
import {useLocation, useNavigate} from "react-router-dom";

const strings = {
    orderNum: "Order #",
    return: "Return to Order",
    open: "Open",
    cancel: "Cancel",
};

type Props = {
    driveThruDetails: posModels.DriveThruDetails | null;
    orderId: string;
    orderNumber: number;
    currentOrderTotalCents: API.models.USDCents;
    startTime: Date;
    onCancel: (id: string) => void;
};

export default function OrdersCurrentOrder(props: Props) {
    const location = useLocation();
    const navigate = useNavigate();

    return (
        <>
            <div className={styles.currentOrderDetails}>
                <div className={styles.currentOrderTitle}>
                    {props.driveThruDetails
                        ? `#${props.orderNumber} ${props.driveThruDetails.vehicleColor} ${props.driveThruDetails.vehicleType}`
                        : `${strings.orderNum}${props.orderNumber}`}
                </div>
                <div>
                    <div className={styles.currentOrderTotal}>
                        {Lib.currency.centsToDollarString(
                            props.currentOrderTotalCents,
                        )}
                    </div>
                    <TimeSince
                        className={styles.currentOrderTime}
                        startTime={props.startTime}
                    />
                </div>
            </div>
            <div className={styles.currentOrderButtons}>
                <OutlineButton
                    className={styles.currentOrderOpenButton}
                    label={
                        location.pathname === routeHelpers.otherOrders()
                            ? strings.return
                            : strings.open
                    }
                    onClick={() => navigate(routeHelpers.payment())}
                />
                {/* TODO: revisit once we revise the drive thru fulfillment role */}
                <OutlineButton
                    mode="red"
                    label={strings.cancel}
                    onClick={() => props.onCancel(props.orderId)}
                />
            </div>
        </>
    );
}
