import * as creditCardScanner from "../../lib/api/creditCardScanner";
import * as posModels from "../../lib/api/posModels";
import creditCardRegisterIcon from "../../images/credit-card-scanner-instruction.svg";
import creditCardRegisterSuccessIcon from "../../images/credit-card-scanner-success.svg";
import creditCardRegisterFailureIcon from "../../images/credit-card-scanner-failure.svg";
import ManagerHeader from "../ManagerHeader";
import OutlineButton from "../OutlineButton";
import React, {useEffect, useState} from "react";
import styles from "./ManagerKioskCCRegistration.module.css";
import Spinner from "../Spinner";

const strings = {
    kioskButtonsLabel: "Select a kiosk to configure its card reader",
    headerText: "Kiosks",
    okay: "Okay",
    alreadyRegistered: "This device has already been registered successfully.",
    registerSuccessMessage: "Credit Card Reader Registered",
    registerFailureMessage:
        "Failed To Register Credit Card Reader. Please Try Again.",
    configureMainPrompt: "Please Configure Card Reader",
    configureInstruction:
        "Please enter the following PIN into the attached credit card reader.",
    mockButtonLabel: "mock enter code on device",
    cancel: "Cancel",
};

const KIOSK_CC_DATA: {label: string; deviceName: posModels.KioskDeviceName}[] =
    [
        {label: "Kiosk 1", deviceName: "kiosk1"},
        {label: "Kiosk 2", deviceName: "kiosk2"},
        {label: "Kiosk 3", deviceName: "kiosk3"},
        {label: "Kiosk 4", deviceName: "kiosk4"},
    ];

function getRandom4DigitString() {
    return Math.floor(Math.random() * 10000)
        .toString()
        .padStart(4, "0");
}

const isMock = import.meta.env.REACT_APP_MOCK_API === "true" ? true : false;

export function ManagerKioskCCRegistration() {
    const [deviceToRegister, setDeviceToRegister] =
        useState<posModels.KioskDeviceName | null>(null);
    const [deviceCode, setDeviceCode] = useState<string>(
        getRandom4DigitString(),
    );
    const [loading, setLoading] = useState(false);
    const [attemptToRegister, setAttemptToRegister] = useState(false);
    const [isDeviceRegistered, setIsDeviceRegistered] = useState(false);
    const [isRegisteredMessaged, setIsRegisteredMessage] = useState("");
    const [registrationFailed, setRegistrationFailed] = useState(false);

    /* when we select a device to register, check the device connection */
    useEffect(() => {
        if (!deviceToRegister) {
            return;
        }

        creditCardScanner
            .testKioskDeviceConnection(deviceToRegister)
            .then((isRegistered) => {
                if (isRegistered) {
                    setIsRegisteredMessage(strings.alreadyRegistered);
                } else {
                    setAttemptToRegister(!isRegistered);
                }
                setIsDeviceRegistered(isRegistered);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [deviceToRegister]);

    useEffect(() => {
        if (isMock || !deviceToRegister || !attemptToRegister) {
            return;
        }

        creditCardScanner
            .registerKioskCardReader(deviceToRegister, deviceCode)
            .then((isRegistered) => {
                setAttemptToRegister(false);
                if (isRegistered) {
                    setIsDeviceRegistered(true);
                    setIsRegisteredMessage(strings.registerSuccessMessage);
                }

                setRegistrationFailed(!isRegistered);
            });
    }, [attemptToRegister, deviceCode, deviceToRegister]);

    const getContent = () => {
        if (deviceToRegister === null) {
            return (
                <div>
                    <div className={styles.kioskListLabel}>
                        {strings.kioskButtonsLabel}
                    </div>
                    <div className={styles.kioskButtonsContainer}>
                        {KIOSK_CC_DATA.map((data) => {
                            return (
                                <OutlineButton
                                    key={data.deviceName}
                                    className={styles.kioskButton}
                                    label={data.label}
                                    onClick={() => {
                                        setLoading(true);
                                        setDeviceToRegister(data.deviceName);
                                    }}
                                />
                            );
                        })}
                    </div>
                </div>
            );
        }

        if (loading) {
            return (
                <div className={styles.loadingContainer}>
                    <Spinner />
                </div>
            );
        }

        if (isDeviceRegistered) {
            return (
                <>
                    <img
                        src={creditCardRegisterSuccessIcon}
                        className={styles.icon}
                        alt=""
                    />
                    <div className={styles.mainText}>
                        {isRegisteredMessaged}
                    </div>
                    <OutlineButton
                        label={strings.okay}
                        className={styles.okayButton}
                        mode="blue"
                        onClick={() => {
                            setDeviceToRegister(null);
                            setDeviceCode(getRandom4DigitString());
                        }}
                    />
                </>
            );
        }

        if (registrationFailed) {
            return (
                <>
                    <img
                        src={creditCardRegisterFailureIcon}
                        className={styles.icon}
                        alt=""
                    />
                    <div className={styles.mainText}>
                        {strings.registerFailureMessage}
                    </div>
                    <OutlineButton
                        label={strings.okay}
                        className={styles.okayButton}
                        mode="blue"
                        onClick={() => {
                            setDeviceCode(getRandom4DigitString());
                            setRegistrationFailed(false);
                            setAttemptToRegister(true);
                        }}
                    />
                </>
            );
        }

        return (
            <>
                <img
                    src={creditCardRegisterIcon}
                    className={styles.icon}
                    alt=""
                />
                <div className={styles.mainText}>
                    {strings.configureMainPrompt}
                </div>
                <div className={styles.secondaryText}>
                    {strings.configureInstruction}
                </div>
                <div className={styles.codeText}>{deviceCode}</div>
                {isMock ? (
                    <OutlineButton
                        label={strings.mockButtonLabel}
                        onClick={() => {
                            setIsDeviceRegistered(true);
                            setIsRegisteredMessage(
                                strings.registerSuccessMessage,
                            );
                        }}
                        mode="blue"
                    />
                ) : null}
            </>
        );
    };

    return (
        <div className={styles.container}>
            <ManagerHeader
                label={strings.headerText}
                buttonLabel={
                    deviceToRegister && !isDeviceRegistered && !loading
                        ? strings.cancel
                        : undefined
                }
                buttonOnClick={() => {
                    setDeviceToRegister(null);
                }}
            />
            <div className={styles.contentContainer}>{getContent()}</div>
        </div>
    );
}
