import * as posActions from "../../lib/posActions";
import * as routeHelpers from "../../lib/routeHelpers";
import CheckoutProductBase from "../CheckoutProductBase";
import cn from "classnames";
import CustomizationList from "../CustomizationList";
import logger from "../../lib/logger";
import React, {useMemo} from "react";
import styles from "./CheckoutItemOrCombo.module.css";
import trashIcon from "../../images/trash.svg";
import {Lib, Actions} from "habit-core";
import {models} from "habit-core/types/api";
import {useAppSelector, useAppDispatch} from "../../lib/hooks";

const MAX_QUANTITY = 99;

const strings = {
    voided: "voided",
};

type Props = {
    className?: string;
    editable: "editable" | "removable" | "uneditable";
    entity: Lib.selectors.CartEntityItemOrCombo;
    onPressSplit: (
        entity: Lib.selectors.CartEntityItemOrCombo,
        currentQuantity: number,
    ) => void;
    voidId?: string;
};

CheckoutItemOrCombo.defaultProps = {
    editable: "editable",
};

export default function CheckoutItemOrCombo({
    entity,
    className,
    editable,
    onPressSplit,
    voidId,
}: Props) {
    const dispatch = useAppDispatch();

    function onChangeQuantity(newQuantity: number) {
        if (entity.type === "item") {
            dispatch(
                posActions.changeCurrentOrderItemQuantity(
                    entity.customizationId,
                    newQuantity,
                ),
            );
        } else if (entity.type === "combo") {
            dispatch(
                posActions.changeCurrentOrderComboQuantity(
                    entity.customizationId,
                    newQuantity,
                ),
            );
        }
    }

    const menuId = useAppSelector((state) => state.currentOrder.menuId);
    const itemsById = useAppSelector((state) => state.items.byId);
    const itemCustomizationsById = useAppSelector(
        (state) => state.customizations.items.byInternalId,
    );
    const comboCustomizationsById = useAppSelector(
        (state) => state.customizations.combos.byInternalId,
    );
    const combosById = useAppSelector((state) => state.combos.byId);
    const voidedItemsById = useAppSelector(
        (state) => state.pos.currentOrderVoided.items.voided.byId,
    );
    const voidedCombosById = useAppSelector(
        (state) => state.pos.currentOrderVoided.combos.voided.byId,
    );

    const getItemCategoryId = useMemo(
        Lib.selectors.createGetCategoryIdForItemId,
        [],
    );
    const getComboCategoryId = useMemo(
        Lib.selectors.createGetCategoryIdForComboId,
        [],
    );

    let priceCents = 0;
    let quantity = 1;
    let comboItems: {
        id: string;
        name: string;
        modifiers: models.ModifierSelectionDict;
        itemId: string;
    }[] = [];
    let comboIsBundle = true;
    let itemId: string | null = null;
    let itemModifiers: models.ModifierSelectionDict | null = null;
    let editHref = "";
    if (entity.type === "combo") {
        const customization = comboCustomizationsById[entity.customizationId];
        comboIsBundle = combosById[customization.comboId]?.isBundle ?? false;
        comboItems = customization.itemCustomizationIds.map((id) => {
            const itemCustomization = itemCustomizationsById[id];
            const name = itemsById[itemCustomization.itemId].name;
            const modifiers = itemCustomization.modifierSelections;
            return {id, name, modifiers, itemId: itemCustomization.itemId};
        });

        quantity = customization.quantity;
        priceCents =
            useAppSelector((state) =>
                Lib.selectors.getComboUnitPriceCents(
                    customization.comboId,
                    menuId,
                    customization.itemCustomizationIds.map(
                        (id) => itemCustomizationsById[id],
                    ),
                    state.menuItems.byMenuId,
                    state.menuItemModifiers.byMenuId,
                    state.menuCombos.byMenuId,
                    state.combos.byId,
                    false,
                    false,
                ),
            ) * quantity;

        const categoryId = useAppSelector((state) =>
            getComboCategoryId(state, customization.comboId),
        );
        if (categoryId) {
            editHref = routeHelpers.combo(
                categoryId,
                customization.comboId,
                entity.customizationId,
            );
        }
    } else if (entity.type === "item") {
        const customization = itemCustomizationsById[entity.customizationId];
        quantity = customization.quantity;
        priceCents =
            useAppSelector((state) =>
                menuId
                    ? state.menuItems.byMenuId[menuId][customization.itemId]
                          .priceCents
                    : 0,
            ) * quantity;
        itemId = customization.itemId;
        itemModifiers = customization.modifierSelections;

        const categoryId = useAppSelector((state) =>
            getItemCategoryId(state, customization.itemId),
        );
        if (categoryId) {
            editHref = routeHelpers.item(
                categoryId,
                customization.itemId,
                entity.customizationId,
            );
        }
    }

    if (voidId) {
        priceCents *= -1 / quantity;
        if (entity.type === "combo") {
            quantity = voidedCombosById[voidId].quantity;
        } else if (entity.type === "item") {
            quantity = voidedItemsById[voidId].quantity;
        }
        priceCents *= quantity;
    }

    const removeProduct = () => {
        if (entity.type === "item") {
            if (voidId) {
                dispatch(posActions.removeVoidedItem(voidId));
            } else {
                dispatch(
                    posActions.removeCurrentOrderItem(entity.customizationId),
                );
            }
        } else if (entity.type === "combo") {
            if (voidId) {
                dispatch(posActions.removeVoidedCombo(voidId));
            } else {
                dispatch(
                    posActions.removeCurrentOrderCombo(entity.customizationId),
                );
            }
        }
    };

    if (!menuId) {
        logger.warn("No menu id for current order");
        return null;
    }

    return (
        <CheckoutProductBase
            className={className}
            editable={editable}
            name={entity.name}
            quantity={quantity}
            formattedPrice={`${
                voidId ? "-" : ""
            }${Lib.currency.centsToDollarString(priceCents)}`}
            onSplit={() => onPressSplit(entity, quantity)}
            onChangeQuantity={onChangeQuantity}
            canIncreaseQuantity={quantity < MAX_QUANTITY && !voidId}
            canDecreaseQuantity={quantity >= 2 && !voidId}
            editTo={editable === "editable" && !voidId ? editHref : undefined}
            onRemove={removeProduct}
            content={
                <>
                    {itemModifiers && itemId ? (
                        <CustomizationList
                            textColor={
                                location.pathname.includes("checkout")
                                    ? "grey"
                                    : "normal"
                            }
                            modifierSelections={itemModifiers}
                            itemId={itemId}
                            editable={false}
                            parentQuantity={quantity}
                            showModifierQuantityString
                        />
                    ) : null}

                    {comboItems
                        ? comboItems.map((c, i) => (
                              <div key={c.id}>
                                  <div
                                      className={styles.comboItemNameContainer}>
                                      <div
                                          className={cn({
                                              [styles.comboItemName]: true,
                                              [styles.comboItemTop]: i === 0,
                                          })}>
                                          {c.name}
                                      </div>
                                      {i !== 0 &&
                                      editable !== "uneditable" &&
                                      !comboIsBundle ? (
                                          <button
                                              className={
                                                  styles.sideRemoveButton
                                              }
                                              onClick={() => {
                                                  dispatch(
                                                      Actions.currentOrderActions.uncomboAndRemoveItem(
                                                          entity.customizationId,
                                                          c.id,
                                                      ),
                                                  );
                                              }}>
                                              <img src={trashIcon} alt="" />
                                          </button>
                                      ) : null}
                                  </div>

                                  <CustomizationList
                                      textColor={
                                          location.pathname.includes("checkout")
                                              ? "grey"
                                              : "normal"
                                      }
                                      modifierSelections={c.modifiers}
                                      itemId={c.itemId}
                                      editable={false}
                                      parentQuantity={quantity}
                                      showModifierQuantityString
                                  />
                              </div>
                          ))
                        : null}
                </>
            }
            leftText={voidId ? strings.voided : undefined}
        />
    );
}
