import * as posActions from "../../lib/posActions";
import * as routeHelpers from "../../lib/routeHelpers";
import * as users from "../../lib/api/users";
import cn from "classnames";
import CurrencyInput from "../CurrencyInput";
import describeNetworkError from "../../lib/describeNetworkError";
import ErrorModal from "../ErrorModal";
import logger from "../../lib/logger";
import OutlineButton from "../OutlineButton";
import PaymentMethodsHeader from "../PaymentMethodsHeader";
import React, {FormEvent, useEffect, useRef, useState} from "react";
import StyledKeyboard from "../StyledKeyboard";
import styles from "./EzCater.module.css";
import Spinner from "../Spinner";
import swipeCardIcon from "../../images/swipe-card.svg";
import TextInput from "../TextInput";
import useOnChangeCashAmount from "../../lib/useOnChangeCashAmount";
import {API, Lib} from "habit-core";
import {useAppDispatch} from "../../lib/hooks";
import {useExternalInput} from "../../lib/api/useExternalInput";
import {usePaidContext} from "../OrderSubtotalLayout";

const strings = {
    backPromptLabel: "Other Payment Options // EzCater",
    enterNumber: "Enter Manager ID Manually",
    enterNumberInput: "Manager ID",
    instruction:
        "A manager must swipe their card in order to mark this order as paid in EzCater.",
    submit: "Submit",
    discountInputLabel: "Ez Cater Discount",
    discountInputPlaceholder: "Enter Discount Amount",
    submitOrder: (balanceDueCents: API.models.USDCents) =>
        `Tender ${Lib.currency.centsToDollarString(balanceDueCents)} Balance`,
    keyboardEnterLabel: "Submit",
};

const MANAGER_CARD_LENGTH = 10;

export function EzCater() {
    const dispatch = useAppDispatch();
    const {balanceDueCents, tempDiscountCents, setTempDiscountCents} =
        usePaidContext();

    const [errorMessage, setErrorMessage] = useState("");
    const [employeeId, setEmployeeId] = useState<string | null>(null);
    const {
        externalInput: cardNumber,
        clearExternalInput: clearCardNumber,
        cardReadError,
    } = useExternalInput(employeeId === null);
    const [submittingCardNumber, setSubmittingCardNumber] = useState(false);

    const inputRef = useRef<HTMLInputElement>(null);
    const submitEmployeeIdButtonRef = useRef<HTMLButtonElement>(null);
    const [keyboardCollapsed, setKeyboardCollapsed] = useState(true);

    const [isManagerVerified, setIsManagerVerified] = useState(false);
    const [ezCaterDiscountCents, setEzCaterDiscountCents] =
        useState<API.models.USDCents | null>(null);
    const [displayedEzCaterDiscount, setDisplayedEzCaterDiscount] =
        useState("");
    const onChangeEzCaterDiscount = useOnChangeCashAmount(
        setDisplayedEzCaterDiscount,
        setEzCaterDiscountCents,
    );

    useEffect(() => {
        setTempDiscountCents(ezCaterDiscountCents ?? 0);
    }, [ezCaterDiscountCents]);

    useEffect(() => {
        if (inputRef.current && !keyboardCollapsed) {
            if (document.activeElement === inputRef.current) {
                inputRef?.current?.scrollIntoView();
            }
        }
    }, [keyboardCollapsed]);

    useEffect(() => {
        if (employeeId === "" && inputRef.current) {
            inputRef.current.focus();
            setKeyboardCollapsed(false);
        }
    }, [employeeId]);

    useEffect(() => {
        if (cardNumber.length === MANAGER_CARD_LENGTH) {
            onSubmitManagerVerification();
        }
    }, [cardNumber]);

    function onSubmitManagerVerification() {
        if ((!employeeId && !cardNumber) || submittingCardNumber) {
            return;
        }

        // validate by employee id if card number is not provided
        if (!cardNumber && employeeId) {
            setSubmittingCardNumber(true);
            users
                .validateManagerById(employeeId)
                .then(() => {
                    setEmployeeId(null);
                    setIsManagerVerified(true);
                    setKeyboardCollapsed(true);
                })
                .catch((err) => {
                    logger.warn(err);
                    const description = describeNetworkError(err);
                    setErrorMessage(description.join("\n"));
                })
                .finally(() => {
                    setSubmittingCardNumber(false);
                    setEmployeeId("");
                });
        } else {
            setSubmittingCardNumber(true);
            users
                .validateManagerCardNumber(cardNumber)
                .then(() => {
                    setIsManagerVerified(true);
                    setEmployeeId(null);
                })
                .catch((err) => {
                    logger.warn(err);
                    const description = describeNetworkError(err);
                    setErrorMessage(description.join("\n"));
                })
                .finally(() => {
                    setSubmittingCardNumber(false);
                    clearCardNumber();
                });
        }
    }

    function onSubmitManagerVerificationForm(
        e: React.FormEvent<HTMLFormElement>,
    ) {
        e.preventDefault();
        onSubmitManagerVerification();
    }

    function getNonManualContent() {
        return (
            <>
                {!submittingCardNumber ? (
                    <>
                        <OutlineButton
                            className={styles.enterNumberButton}
                            label={strings.enterNumber}
                            onClick={() => {
                                setEmployeeId("");
                            }}
                            type="button"
                        />
                        {errorMessage ? (
                            <div className={styles.pinError}>
                                {errorMessage}
                            </div>
                        ) : null}
                    </>
                ) : (
                    <div className={styles.cardSwipeLoadingIndicator}>
                        <Spinner />
                    </div>
                )}
            </>
        );
    }

    function getManualContent() {
        if (employeeId === null) {
            return null;
        }

        return (
            <div className={styles.inputContainer}>
                <TextInput
                    ref={inputRef}
                    className={styles.pinInput}
                    value={employeeId}
                    onChange={(e) => {
                        setEmployeeId(e.target.value);
                        setErrorMessage("");
                    }}
                    onFocus={() => setKeyboardCollapsed(false)}
                    placeholder={strings.enterNumberInput}
                    required={true}
                    inputMode="none"
                />
                {errorMessage ? (
                    <div className={styles.pinError}>{errorMessage}</div>
                ) : null}
                <OutlineButton
                    ref={submitEmployeeIdButtonRef}
                    className={styles.submitEmployeeIdButton}
                    label={strings.submit}
                    type="submit"
                    disabled={!employeeId}
                    loading={submittingCardNumber}
                />
            </div>
        );
    }

    function getManagerVerificationContent() {
        return (
            <>
                <form
                    className={styles.managerVerificationContentContainer}
                    onSubmit={onSubmitManagerVerificationForm}>
                    <img className={styles.icon} src={swipeCardIcon} alt="" />
                    <div className={styles.instructions}>
                        {strings.instruction}
                    </div>
                    {employeeId === null
                        ? getNonManualContent()
                        : getManualContent()}
                </form>

                <div
                    className={cn(
                        styles.keyboardContainer,
                        keyboardCollapsed && styles.keyboardContainerCollapsed,
                    )}>
                    <StyledKeyboard
                        currentInput={employeeId ?? ""}
                        visible={!keyboardCollapsed}
                        setVisible={(val: boolean) =>
                            setKeyboardCollapsed(!val)
                        }
                        onChange={(input) => setEmployeeId(input)}
                        onPressEnter={onSubmitManagerVerification}
                        inputRefs={[inputRef]}
                        ignoreKeyboardDismissRefs={[submitEmployeeIdButtonRef]}
                    />
                </div>
            </>
        );
    }

    const onSubmitOrderForm = (e: FormEvent) => {
        e.preventDefault();
        onSubmitOrder();
    };

    const onSubmitOrder = () => {
        if (ezCaterDiscountCents) {
            setTempDiscountCents(0);
            dispatch(
                posActions.currentOrderAddDiscount(
                    "ez-cater-discount",
                    ezCaterDiscountCents,
                    null,
                    "ez_cater",
                ),
            );
        }

        dispatch(posActions.applyEzCater());
    };

    function getPostManagerVerificationContent() {
        return (
            <>
                <form
                    className={styles.ezCaterDiscountContentContainer}
                    onSubmit={onSubmitOrderForm}>
                    <label className={styles.inputLabel}>
                        {strings.discountInputLabel}
                    </label>
                    <CurrencyInput
                        placeholder={strings.discountInputPlaceholder}
                        value={displayedEzCaterDiscount}
                        onChange={(num) => setEzCaterDiscountCents(num)}
                    />
                    <OutlineButton
                        className={styles.submitOrderButton}
                        label={strings.submitOrder(
                            Math.max(balanceDueCents - tempDiscountCents, 0),
                        )}
                        type="submit"
                    />
                </form>
                <div className={styles.discountAmountKeyboardContainer}>
                    <StyledKeyboard
                        currentInput={displayedEzCaterDiscount}
                        alwaysVisible
                        onChange={onChangeEzCaterDiscount}
                        onPressEnter={onSubmitOrder}
                        enterLabel={strings.keyboardEnterLabel}
                        inputRefs={[]}
                        layout="numeric"
                        disableCaretPositioning
                    />
                </div>
            </>
        );
    }

    return (
        <>
            <div className={styles.container}>
                <PaymentMethodsHeader
                    backPromptLabel={strings.backPromptLabel}
                    to={routeHelpers.otherPaymentMethods()}
                />
                {isManagerVerified
                    ? getPostManagerVerificationContent()
                    : getManagerVerificationContent()}
            </div>
            {cardReadError ? (
                <ErrorModal
                    title={cardReadError.title}
                    errorMessage={cardReadError.message}
                    backgroundColor="grey"
                    onClose={() => clearCardNumber()}
                />
            ) : null}
        </>
    );
}
