import * as posActions from "../../lib/posActions";
import * as routeHelpers from "../../lib/routeHelpers";
import * as searchParamNames from "../../constants/searchParamNames";
import AddCashAmount from "../AddCashAmount";
import checkIdIcon from "../../images/check-id.svg";
import cn from "classnames";
import describeNetworkError from "../../lib/describeNetworkError";
import ErrorModal from "../ErrorModal";
import OutlineButton from "../OutlineButton";
import PaymentHeader from "../PaymentHeader";
import React, {useState, useEffect, useRef, useContext} from "react";
import StyledKeyboard from "../StyledKeyboard";
import styles from "./CheckoutStep2.module.css";
import TextModal from "../TextModal";
import ToggleButton from "../ToggleButton";
import useOnChangeCashAmount from "../../lib/useOnChangeCashAmount";
import {API, Lib} from "habit-core";
import {
    Route,
    Routes,
    useLocation,
    useNavigate,
    useSearchParams,
} from "react-router-dom";
import {usePaidContext} from "../OrderSubtotalLayout";
import {useAppSelector, useAppDispatch} from "../../lib/hooks";
import {CashierModeContext} from "../CashierModeContext";
import {ParallelModeContext} from "../ParallelModeContext";
import CurrencyInput from "../CurrencyInput";

const strings = {
    payment: "How would you like to pay?",
    cash: "Cash",
    card: "Credit Card",
    addCash: "Add cash amount",
    other: "Other payment methods",
    discount: "Discount",
    giftCard: "Gift or Comp Card",
    otherPaymentMethods: "Other",
    ccInstructions:
        "Instruct guest to insert or swipe their credit card in the machine provided",
    giftCardInstructionsHeader: "Ask For Guest's ID & Credit Card.",
    giftCardInstructions:
        "Once ID is confirmed, instruct guest to insert or swipe their Credit Card in the machine provided.",

    returnToOrder: "Return To Order #",
    return: "Return",

    orderComplete: "Order Completed",
    returnChange: "Please return guest's change",
    instructions:
        "Your pager will light up and vibrate when your order is ready. Thank you!",
    close: "Close",
    tip: "Tip",
    ccAproved: "Credit Card Approved",
    ccDeclined: "Credit Card Declined",
    ccDeclinedMockMessage: "The credit card transaction has been declined.",
    ok: "Ok",
    ccUserCancelledMessage:
        "The credit card transaction was cancelled on the pin pad. Please select the payment type.",
    cardDeclinedGenericError:
        "The credit card transaction was not approved. Please try again, or select a different payment type",
};

const TRAINING_CREDIT_CARD_NUMBER = "4111111111111111";

const CREDIT_CARD_MACHINE_ENABLED =
    import.meta.env.REACT_APP_CREDIT_CARD_SCANNER_ENABLED === "true";

const IS_MOCK = import.meta.env.REACT_APP_MOCK_API === "true";

type PaymentMethod = "cash" | "credit_card";

type NameState = {
    name?: string;
};

const cashCentsButtons = [100, 500, 1000, 2000];

type Props = {
    showReturnPrompt: boolean;
};

export default function CheckoutStep2(props: Props) {
    const {balanceDueCents, taxCents} = usePaidContext();
    const zeroBalanceDue = balanceDueCents <= 0;

    const {isParallel} = useContext(ParallelModeContext);
    const {
        isTraining,
        errorAfterPaymentsProcessed,
        setResetCurrentOrderStateOnCartEmptied,
    } = useContext(CashierModeContext);
    const stationMode = useAppSelector((state) => state.pos.station.mode);

    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>("cash");
    const [cashAmountCents, setCashAmountCents] =
        useState<API.models.USDCents | null>(null);
    const [displayedCashAmount, setDisplayedCashAmount] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [ccPaymentErrorModalTitle, setCCPaymentErrorModalTitle] =
        useState("");
    const [ccPaymentErrorModalBody, setCCPaymentErrorModalBody] = useState("");
    const dispatch = useAppDispatch();
    const posCurrentOrder = useAppSelector((state) => state.pos.currentOrder);

    // find out if any discounts have been applied
    const discountsApplied = useAppSelector(
        (state) => state.pos.currentOrder.discounts,
    );
    const punchhOrManagerDiscountsOnOrder = !!discountsApplied.find(
        (discount) =>
            discount.discountType === "punchh" ||
            discount.discountType === "manager",
    );

    const purchaseGiftCards = useAppSelector(
        (state) => state.pos.currentOrder.giftCards.purchase,
    );
    const addFundsGiftCards = useAppSelector(
        (state) => state.pos.currentOrder.giftCards.addFunds,
    );
    const gcPurchasesOnOrder =
        purchaseGiftCards.length > 0 || addFundsGiftCards.length > 0;

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const isRequestingCCPayment =
        searchParams.get(searchParamNames.REQUESTING_CC_PAYMENT) === "true";
    const location = useLocation();
    const locationState = location.state as NameState;
    const customerName = locationState?.name ?? null;

    const onApplyCash = () => {
        if (cashAmountCents === null || zeroBalanceDue) {
            return;
        }

        dispatch(posActions.addToPaymentCashPaidCents(cashAmountCents));
        setCashAmountCents(0);
    };

    /* keyboard */
    const cashAmountInputRef = useRef<HTMLInputElement>(null);
    const mockTipAmountRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const floatVal = parseFloat(displayedCashAmount);
        if (
            isNaN(floatVal) ||
            cashAmountCents !== Lib.currency.dollarsFloatToCents(floatVal)
        ) {
            setDisplayedCashAmount(
                cashAmountCents !== null
                    ? Lib.currency
                          .centsToDollarString(cashAmountCents)
                          .replace("$", "")
                    : "",
            );
        }
    }, [cashAmountCents]);

    const onChangeCashAmount = useOnChangeCashAmount(
        setDisplayedCashAmount,
        setCashAmountCents,
    );

    const [mockTipAmountCents, setMockTipAmountCents] =
        useState<API.models.USDCents | null>(null);
    const [displayedMockTipAmount, setDisplayedMockTipAmount] = useState("");

    const onChangeMockTipAmount = useOnChangeCashAmount(
        setDisplayedMockTipAmount,
        setMockTipAmountCents,
    );

    const showCCPayment = () => {
        setPaymentMethod("credit_card");
        navigate(routeHelpers.payment(true), {
            replace: true,
            state: {name: customerName},
        });
    };

    const showCashPayment = () => {
        navigate(routeHelpers.payment(false), {
            replace: true,
            state: {
                name: customerName,
            },
        });
        setPaymentMethod("cash");
    };

    function renderCreditCardInstructions() {
        if (errorAfterPaymentsProcessed) {
            return null;
        }

        if (
            isTraining ||
            isParallel ||
            IS_MOCK ||
            !CREDIT_CARD_MACHINE_ENABLED
        ) {
            return (
                <div>
                    <CurrencyInput
                        ref={mockTipAmountRef}
                        value={displayedMockTipAmount}
                        onChange={(num) => setMockTipAmountCents(num)}
                        placeholder={strings.tip}
                    />
                    <OutlineButton
                        className={styles.creditCardApprovedButton}
                        label={strings.ccAproved}
                        onClick={() => {
                            navigate(routeHelpers.payment(false), {
                                replace: true,
                                state: {name: customerName},
                            });

                            dispatch(
                                posActions.reserveCurrentOrderRefNumIfNeeded(
                                    isTraining,
                                ),
                            ).then(() => {
                                dispatch(
                                    posActions.applyPaymentCreditCard({
                                        cardNumber: TRAINING_CREDIT_CARD_NUMBER,
                                        ccType: "VISA",
                                        appliedAmountCents:
                                            balanceDueCents +
                                            (mockTipAmountCents ?? 0),
                                        authId: "fake-auth-id",
                                        termId: "1234356",
                                        merchId: "123456",
                                        cardholderName: "fake cardholder",
                                        cardExpMonth: "06",
                                        cardExpYear: "2024",
                                        cardToken: "123456",
                                        ctroutd: "123456",
                                        troutd: "123456",
                                        tipAmountCents: mockTipAmountCents ?? 0,
                                    }),
                                );
                            });
                        }}
                    />
                    <OutlineButton
                        className={styles.creditCardApprovedButton}
                        label={strings.ccDeclined}
                        onClick={() => {
                            // adding this here to mimic having a ref num reserved even if declined.
                            dispatch(
                                posActions.reserveCurrentOrderRefNumIfNeeded(
                                    isTraining,
                                ),
                            ).then(() => {
                                navigate(routeHelpers.payment(false), {
                                    replace: true,
                                    state: {name: customerName},
                                });
                                setResetCurrentOrderStateOnCartEmptied(
                                    stationMode !==
                                        "drive_thru_order_fulfillment",
                                );
                                setPaymentMethod("cash");
                                setCCPaymentErrorModalTitle(strings.ccDeclined);
                                setErrorMessage(strings.ccDeclinedMockMessage);
                            });
                        }}
                    />
                </div>
            );
        }

        return (
            <div
                className={cn(
                    styles.center,
                    styles.instructions,
                    purchaseGiftCards.length && styles.giftCardInstructions,
                )}>
                {purchaseGiftCards.length ? (
                    <>
                        <img src={checkIdIcon} alt="" />
                        <div className={styles.giftCardInstructionsHeader}>
                            {strings.giftCardInstructionsHeader}
                        </div>
                        <div>{strings.giftCardInstructions}</div>
                    </>
                ) : (
                    strings.ccInstructions
                )}
            </div>
        );
    }

    function onSelectCCPayment() {
        showCCPayment();
        if (!IS_MOCK && CREDIT_CARD_MACHINE_ENABLED) {
            dispatch(posActions.reserveCurrentOrderRefNumIfNeeded(isTraining))
                .then(() => {
                    dispatch(
                        posActions.requestCCPayment(
                            stationMode,
                            Math.min(taxCents, balanceDueCents),
                            balanceDueCents,
                        ),
                    )
                        .then((data) => {
                            if (data.isApproved) {
                                setResetCurrentOrderStateOnCartEmptied(false);
                                dispatch(
                                    posActions.applyPaymentCreditCard({
                                        cardNumber: data.cardNumber,
                                        ccType: data.ccType,
                                        appliedAmountCents: data.approvedAmount,
                                        authId: data.authId,
                                        termId: data.termId,
                                        merchId: data.merchId,
                                        cardholderName: data.cardholderName,
                                        cardExpMonth: data.cardExpMonth,
                                        cardExpYear: data.cardExpYear,
                                        cardToken: data.cardToken,
                                        ctroutd: data.ctroutd,
                                        troutd: data.troutd,
                                        tipAmountCents: data.tipAmount,
                                    }),
                                );
                            } else {
                                // we don't want to reserve a new order id if the payment taker clears the cart content for some reason after a cc payment is declined
                                setResetCurrentOrderStateOnCartEmptied(
                                    stationMode !==
                                        "drive_thru_order_fulfillment",
                                );
                                setCCPaymentErrorModalTitle(
                                    `${strings.card} - ${data.status}`,
                                );
                                if (data.status === "Cancelled") {
                                    setCCPaymentErrorModalBody(
                                        strings.ccUserCancelledMessage,
                                    );
                                } else {
                                    setCCPaymentErrorModalBody(
                                        data.statusDescription ??
                                            strings.cardDeclinedGenericError,
                                    );
                                }

                                showCashPayment();
                            }
                        })
                        .catch((err) => {
                            setResetCurrentOrderStateOnCartEmptied(
                                stationMode !== "drive_thru_order_fulfillment",
                            );
                            setErrorMessage(
                                describeNetworkError(err).toString(),
                            );
                            showCashPayment();
                        });
                })
                .catch((err) => {
                    setErrorMessage(describeNetworkError(err).toString());
                    showCashPayment();
                });
        }
    }

    function renderBottom() {
        const showKeyboard =
            paymentMethod === "cash" || IS_MOCK || isTraining || isParallel;
        return (
            <Routes>
                <Route
                    index
                    element={
                        <>
                            <div className={styles.label}>
                                {strings.payment}
                            </div>
                            <div className={styles.buttonRow}>
                                <ToggleButton
                                    className={styles.button}
                                    checked={paymentMethod === "cash"}
                                    onChange={() => setPaymentMethod("cash")}
                                    label={strings.cash}
                                    disabled={
                                        isRequestingCCPayment ||
                                        errorAfterPaymentsProcessed
                                    }
                                />
                                <ToggleButton
                                    className={styles.button}
                                    checked={paymentMethod === "credit_card"}
                                    onChange={onSelectCCPayment}
                                    label={strings.card}
                                    disabled={
                                        errorAfterPaymentsProcessed ||
                                        zeroBalanceDue
                                    }
                                />
                            </div>
                            <div className={styles.buttonRow}>
                                <OutlineButton
                                    mode="blue"
                                    className={styles.button}
                                    label={strings.discount}
                                    onClick={() => {
                                        navigate(routeHelpers.discount(), {
                                            state: {
                                                name: customerName,
                                            },
                                        });
                                    }}
                                    disabled={
                                        punchhOrManagerDiscountsOnOrder ||
                                        isRequestingCCPayment ||
                                        errorAfterPaymentsProcessed ||
                                        gcPurchasesOnOrder ||
                                        zeroBalanceDue
                                    }
                                />
                                <OutlineButton
                                    mode="blue"
                                    className={styles.button}
                                    label={strings.giftCard}
                                    onClick={() =>
                                        navigate(
                                            routeHelpers.giftCardPayment(),
                                            {
                                                state: {
                                                    name: customerName,
                                                },
                                            },
                                        )
                                    }
                                    disabled={
                                        isRequestingCCPayment ||
                                        errorAfterPaymentsProcessed ||
                                        gcPurchasesOnOrder ||
                                        zeroBalanceDue
                                    }
                                />
                                <OutlineButton
                                    mode="blue"
                                    className={styles.button}
                                    label={strings.otherPaymentMethods}
                                    onClick={() => {
                                        navigate(
                                            routeHelpers.otherPaymentMethods(),
                                            {
                                                state: {
                                                    name: customerName,
                                                },
                                            },
                                        );
                                    }}
                                    disabled={
                                        isRequestingCCPayment ||
                                        errorAfterPaymentsProcessed ||
                                        gcPurchasesOnOrder ||
                                        zeroBalanceDue
                                    }
                                />
                            </div>

                            {paymentMethod === "cash" &&
                            !errorAfterPaymentsProcessed ? (
                                <AddCashAmount
                                    className={styles.center}
                                    label={strings.addCash}
                                    optionsCents={cashCentsButtons}
                                    cashAmountCents={cashAmountCents}
                                    setCashAmountCents={setCashAmountCents}
                                    displayedCashAmount={displayedCashAmount}
                                    exactCashAmountCents={balanceDueCents}
                                    ref={cashAmountInputRef}
                                />
                            ) : (
                                renderCreditCardInstructions()
                            )}

                            {showKeyboard ? (
                                <div className={styles.keyboardContainer}>
                                    <StyledKeyboard
                                        className={styles.keyboard}
                                        currentInput={
                                            paymentMethod === "cash"
                                                ? displayedCashAmount
                                                : displayedMockTipAmount
                                        }
                                        alwaysVisible
                                        onChange={
                                            paymentMethod === "cash"
                                                ? onChangeCashAmount
                                                : onChangeMockTipAmount
                                        }
                                        inputRefs={[
                                            cashAmountInputRef,
                                            mockTipAmountRef,
                                        ]}
                                        layout="numeric-no-enter"
                                    />
                                </div>
                            ) : null}

                            {paymentMethod === "cash" ? (
                                <OutlineButton
                                    label={"Apply"}
                                    onClick={onApplyCash}
                                    disabled={!cashAmountCents}
                                    className={styles.applyCashButton}
                                    mode="blue"
                                />
                            ) : null}

                            {props.showReturnPrompt ? (
                                <TextModal
                                    className={styles.returnModal}
                                    overlayClassName={styles.returnModalOverlay}
                                    title={`${strings.returnToOrder}${posCurrentOrder.orderNumber}`}
                                    content=""
                                    onClose={() =>
                                        navigate(routeHelpers.payment())
                                    }
                                    cancelLabel={strings.return}
                                />
                            ) : null}
                        </>
                    }
                />
            </Routes>
        );
    }

    return (
        <div className={styles.keyboardAndFormContainer}>
            <div className={styles.container}>
                <PaymentHeader
                    showCheckoutReturnPrompt={
                        !isRequestingCCPayment &&
                        !errorAfterPaymentsProcessed &&
                        stationMode !== "drive_thru_order_fulfillment"
                    }
                    customerName={customerName}
                />
                <div className={styles.bottom}>
                    {posCurrentOrder.id && posCurrentOrder.startTime
                        ? renderBottom()
                        : null}
                </div>
            </div>

            {errorMessage ? (
                <ErrorModal
                    onClose={() => setErrorMessage("")}
                    errorMessage={errorMessage}
                    showITInfo
                />
            ) : null}
            {ccPaymentErrorModalBody ? (
                <TextModal
                    title={ccPaymentErrorModalTitle}
                    content={ccPaymentErrorModalBody}
                    onClose={() => {
                        setCCPaymentErrorModalTitle("");
                        setCCPaymentErrorModalBody("");
                    }}
                    cancelLabel={strings.ok}
                />
            ) : null}
        </div>
    );
}
