import * as routeHelpers from "../../lib/routeHelpers";
import cn from "classnames";
import lockIcon from "../../images/lock.svg";
import React, {useContext} from "react";
import styles from "./ManagerLinks.module.css";
import {CashierModeContext} from "../CashierModeContext";
import {NavLink} from "react-router-dom";
import {TillAssignmentContext} from "../TillAssignmentContext";
import {useAppSelector} from "../../lib/hooks";

const strings = {
    cancel: "Cancel Order",
    voidOrder: "Void",
    discounts: "Discounts",
    noSale: "No Sale",
    training: "Training",
    printers: "Printers",
    previousOrders: "Previous Orders",
    paidOuts: "Paid Outs",
    assignManagerCard: "Manager Card",
    settings: "Settings",
    reports: "Reports",
    removeTill: "Remove Till",
    kioskCardReaderRegistration: "Kiosk Card Readers",
};

const LINKS = [
    {
        to: routeHelpers.previousOrders(),
        label: strings.previousOrders,
        requiresAuth: false,
    },
    {
        to: routeHelpers.reports(),
        label: strings.reports,
        requiresAuth: false,
    },
    {
        to: routeHelpers.cancelOrder(),
        label: strings.cancel,
        requiresAuth: true,
    },
    {
        to: routeHelpers.voidOrder(),
        label: strings.voidOrder,
        requiresAuth: true,
    },
    {
        to: routeHelpers.discounts(),
        label: strings.discounts,
        requiresAuth: true,
    },
    {to: routeHelpers.noSale(), label: strings.noSale, requiresAuth: true},
    {
        to: routeHelpers.removeTill(),
        label: strings.removeTill,
        requiresAuth: true,
    },
    {
        to: routeHelpers.training(),
        label: strings.training,
        requiresAuth: true,
    },
    {
        to: routeHelpers.printers(),
        label: strings.printers,
        requiresAuth: true,
    },
    {
        to: routeHelpers.kioskCCRegistration(),
        label: strings.kioskCardReaderRegistration,
        requiresAuth: true,
    },
    import.meta.env.REACT_APP_PAID_OUT_ENABLED === "true"
        ? {
              to: routeHelpers.paidOuts(),
              label: strings.paidOuts,
              requiresAuth: true,
          }
        : undefined,
    {
        to: routeHelpers.assignManagerCard(),
        label: strings.assignManagerCard,
        requiresAuth: true,
    },
    {
        to: routeHelpers.editSettings(),
        label: strings.settings,
        requiresAuth: true,
    },
];

type Props = {
    className?: string;
};

export default function ManagerLinks(props: Props) {
    const {cashierMode, isTraining} = useContext(CashierModeContext);
    const {assignedTill, trainingModeAssignedTill} = useContext(
        TillAssignmentContext,
    );
    const isTillAssigned = isTraining
        ? trainingModeAssignedTill !== -1
        : assignedTill !== -1;
    const isManager = cashierMode.type === "manager";

    /* discounts can't be applied if there are gift cards on the order */
    const purchaseGiftCards = useAppSelector(
        (state) => state.pos.currentOrder.giftCards.purchase,
    );
    const addFundsGiftCards = useAppSelector(
        (state) => state.pos.currentOrder.giftCards.addFunds,
    );
    const gcPurchasesOnOrder =
        purchaseGiftCards.length > 0 || addFundsGiftCards.length > 0;

    return (
        <div className={props.className}>
            {LINKS.map((l, i) => {
                if (!l) {
                    return null;
                }
                const isRemoveTillLink = l.to === routeHelpers.removeTill();
                const isDiscountsLink = l.to === routeHelpers.discounts();

                return (
                    <NavLink
                        key={l.to}
                        className={({isActive}) =>
                            cn({
                                [styles.link]: true,
                                [styles.firstLink]: i === 0,
                                [styles.lastLink]: i === LINKS.length - 1,
                                [styles.active]: isActive,
                                [styles.locked]:
                                    (!isManager && l.requiresAuth) ||
                                    (!isTillAssigned && isRemoveTillLink) ||
                                    (gcPurchasesOnOrder && isDiscountsLink),
                            })
                        }
                        to={
                            (!isManager && l.requiresAuth) ||
                            (!isTillAssigned && isRemoveTillLink) ||
                            (gcPurchasesOnOrder && isDiscountsLink)
                                ? routeHelpers.managerMenu()
                                : l.to
                        }>
                        {!isManager && l.requiresAuth ? (
                            <img
                                className={styles.lockIcon}
                                src={lockIcon}
                                alt=""
                            />
                        ) : null}
                        <div>{l.label}</div>
                    </NavLink>
                );
            })}
        </div>
    );
}
